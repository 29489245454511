
import mobsliderimage1 from "../../assets/Home/bannersamplemobile.png"
import mobsliderimage2 from "../../assets/Home/bannersamplemobile2.png"
import mobsliderimage3 from "../../assets/Home/bannersamplemobile3.png"
import mobsliderimage4 from "../../assets/Home/bannersamplemobile4.png"
import mobsliderimage5 from "../../assets/Home/bannersamplemobile6.png"

// import sliderimage4 from "../../assets/Home/";
// import sliderimage5 from "../../assets/Home/home-page-banner-5.jpg";





export const MobileSlider = [
    {
        id:"1", 
        src: `${mobsliderimage1}`,
      
    },
    {
        id:"2", 
        src: `${mobsliderimage5}`,
      
    },
    {
        id:"3", 
        src: `${mobsliderimage4}`,
      
    },
    {
        id:"4", 
        src: `${mobsliderimage3}`,
      
    },
    {
        id:"5", 
        src: `${mobsliderimage2}`,
      
    },
];
