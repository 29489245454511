
export const car_wash = [
    {
        heading: "Now & Forever Clean, Parker",
        des: "- 251 W Parker Rd. Houston, Texas 77076",
        btn_text: "Explore More",
        btn_link: "https://maps.app.goo.gl/f4NBprrFoCpFLMQc6"
    },
    {
        heading: "Now & Forever Clean, Mason",
        des: "- 20626 FM 1093, Katy, TX 77450",
        btn_text: "Explore More",
        btn_link: "https://maps.app.goo.gl/g7iUBbAPoaBLYPk66"
    },
    {
        heading: "Now & Forever Clean, Fry",
        des: "- 20607 Westheimer Parkway Katy, TX 77450",
        btn_text: "Explore More",
        btn_link: "https://maps.app.goo.gl/gXj7QD3k2Y7cWJxT7"
    },
    {
        heading: "Now & Forever Clean, Briar",
        des: "- 1585 HWY 6 South Houston, Texas 77077",
        btn_text: "Explore More",
        btn_link: "https://maps.app.goo.gl/qufkxe4nECQJpww37"
    },
];