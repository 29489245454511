import logo from '../../assets/main-logo.svg'
// import logo_shopee from '../../assets/shopee_logo.png'

export const branches = [
  { id: 1, name: 'Coastal Mart', lat: 29.7155507, lng: -95.5831351, address: '11611 Alief Clodine Rd Houston, TX 77082', src: `${logo}`, loc: 'https://maps.app.goo.gl/uJaBmaQFHPeP3bs9A', tel: '(281)-495-7436', mail: 'coastalstore@gmail.com' },
  { id: 2, name: 'Texas Forever Beechnut', lat: 29.6889727, lng: -95.6253633, address: '13600 Beechnut St, Houston, Texas, 77083', src: `${logo}`, loc: 'https://maps.app.goo.gl/13GHJg5K87otvYDv9', tel: '832-379-3446', mail: 'tfbeechnut@gmail.com'},
  { id: 3, name: 'Now & Forever Beechnut 2', lat: 29.6890903, lng: -95.654304, address: '15051 Beechnut St Houston, Texas, 77083', src: `${logo}`, loc: 'https://maps.app.goo.gl/JG3AMqJzpncBEQnDA', tel: '281-506-7945', mail: 'nandfbeechnut@gmail.com'},
  { id: 4, name: 'Texas Forever Channelview', lat: 29.7892486, lng: -95.0939801, address: '17124 East Freeway Channelview, Texas 77530', src: `${logo}`, loc: 'https://maps.app.goo.gl/FzvT2B3hSsbt9RKu9', tel: '281-506-7945', mail: 'zeestruckstop@gmail.com'},
  { id: 5, name: 'Now & Forever Briar Forest', lat: 29.756259, lng: -95.6487764, address: '1585 HWY 6 South Houston, Texas, 77077', src: `${logo}`, loc: 'https://maps.app.goo.gl/qufkxe4nECQJpww37', tel: '281-506-7945', mail: 'briarforestshell@gmail.com'},
  { id: 6, name: 'Now & Forever Westheimer', lat: 29.7354519, lng: -95.6373114, address: '13905 Westheimer Rd Houston, TX, 77077', src: `${logo}`, loc: 'https://maps.app.goo.gl/dkBqShvw4Vk9saBdA', tel: '281-506-7945', mail: 'nandfwestheimer@gmail.com'},
  { id: 7, name: 'Now & Forever Parker', lat: 29.8591699, lng: -95.4038817, address: '251 W Parker Rd. Houston, Texas, 77076', src: `${logo}`, loc: 'https://maps.app.goo.gl/f4NBprrFoCpFLMQc6', tel: '281-506-7945', mail: 'nandfparker@gmail.com'},
  { id: 8, name: 'Now & Forever Bissonnet', lat: 29.6813471, lng: -95.6253216, address: '13646 Bissonnet St Houston, Texas, 77083', src: `${logo}`, loc: 'https://maps.app.goo.gl/c4y4u4p8zn9DoXoQA', tel: '281-506-7945', mail: 'nandfbissonnet@gmail.com'},
  { id: 9, name: 'Now & Forever Windchase', lat: 29.7363982, lng: -95.6328856, address: '13450 Westheimer Rd. Houston, Texas, 77077', src: `${logo}`, loc: 'https://maps.app.goo.gl/M6w8S2bySQuR2LjP8', tel: '281-506-7945', mail: ''},
  { id: 10, name: 'Now & Forever South Main', lat: 29.624587, lng: -95.5821525, address: '4223 South Main St Stafford, Texas, 77477', src: `${logo}`, loc: 'https://maps.app.goo.gl/xXGSYPHzZe632WUa6', tel: '281-506-7945', mail: 'nandfsmain@gmail.com'},
  { id: 11, name: 'Now & Forever Kirkwood', lat: 29.6584806, lng: -95.5855029, address: '11660 West Bellfort Ave Houston, Texas, 77099', src: `${logo}`, loc: 'https://maps.app.goo.gl/YfNo3npvBPbLEBtr9', tel: '281-506-7945', mail: 'nandfkirkwood@gmail.com'},
  { id: 12, name: 'Now & Forever Synott', lat: 29.6623101, lng: -95.6210341, address: '13380 W Bellfort Ave, Houston, TX 77099', src: `${logo}`, loc: 'https://maps.app.goo.gl/XBv3VfjnMgjmNPFn9', tel: '281-506-7945', mail: 'nandfsynott@gmail.com'},
  { id: 13, name: 'Breaktime Chimney Rock', lat: 29.713192, lng: -95.4808907, address: '6225 Chimney Rock Rd, Houston, TX 77081', src: `${logo}`, loc: 'https://maps.app.goo.gl/X22u2VCuFTZfnGb29', tel: '281-506-7945', mail: 'salbreaktime23@gmail.com'},
  { id: 14, name: 'Now & Forever Richmond', lat: 29.7303635, lng: -95.6100731, address: '3151 S Dairy Ashford Rd, Houston, TX 77082', src: `${logo}`, loc: 'https://maps.app.goo.gl/57mYj7mfG3P6V5K89', tel: '281-506-7945', mail: 'dairyashford3151@gmail.com'},
  { id: 15, name: 'Breaktime Harwin', lat: 29.7174346, lng: -95.5411479, address: '9995 Harwin Dr., Houston, TX 77036', src: `${logo}`, loc: 'https://maps.app.goo.gl/kJWtSKYiHxJ3HpEZ9', tel: '281-506-7945', mail: 'salbreaktimeharwin@gmail.com'},
  { id: 16, name: 'Now & Forever 59', lat: 29.691745, lng: -95.5268088, address: '8103 Southwest Fwy, Houston, TX 77074', src: `${logo}`, loc: 'https://maps.app.goo.gl/aNzAEDip8o2GPBkv6', tel: '281-506-7945', mail: 'nandf59beechnut@gmail.com'},
  { id: 17, name: 'Now & Forever Southwest Freeway', lat: 29.7187296, lng: -95.5003992, address: '6500 Southwest Fwy, Houston, TX 77074', src: `${logo}`, loc: 'https://maps.app.goo.gl/jDdznafL9QPuZj9A6', tel: '281-506-7945', mail: 'nandfsouthwestfreeway@gmail.com'},
  { id: 18, name: 'Now & Forever Highway 6', lat: 29.7350916, lng: -95.6439832, address: '14531 Westheimer Rd, Houston, TX 77077', src: `${logo}`, loc: 'https://maps.app.goo.gl/XQuQotmox39M8tGc7', tel: '281-506-7945', mail: 'nandfhwy6@gmail.com'},
  { id: 19, name: 'Now & Forever Eldridge', lat: 29.7118792, lng: -95.6275062, address: '13629 Alief Clodine Rd, Houston, TX 77082', src: `${logo}`, loc: 'https://maps.app.goo.gl/vBs6Ee81SpWVPwCD9', tel: '281-506-7945', mail: 'nandfeldridge@gmail.com'},
  { id: 20, name: 'Now & Forever Galveston', lat: 29.6479223, lng: -95.2243602, address: '7450 Galveston Rd, Houston, TX 77034', src: `${logo}`, loc: 'https://maps.app.goo.gl/BpT1oCsexLUNNp4SA', tel: '281-506-7945', mail: 'nandfgalveston@gmail.com'},
  { id: 21, name: 'Now & Forever Shepherd', lat: 29.7783436, lng: -95.4115222, address: '4740 Katy Fwy, Houston, TX 77007', src: `${logo}`, loc: 'https://maps.app.goo.gl/EKdbb6krMpMztv48A', tel: '281-506-7945', mail: 'nandfshepherd@gmail.com'},
  { id: 22, name: 'Now & Forever MLK', lat: 29.6881338, lng: -95.3427835, address: '6333 Martin Luther King Blvd, Houston, TX 77021', src: `${logo}`, loc: 'https://maps.app.goo.gl/Bwyk5G1ZCyAHanMt9', tel: '281-506-7945', mail: 'nandfmlk@gmail.com'},
  { id: 23, name: 'Now & Forever Fry', lat: 29.7379523, lng: -95.7359496, address: '20607 Westheimer Pkwy, Katy, TX 77450', src: `${logo}`, loc: 'https://maps.app.goo.gl/gXj7QD3k2Y7cWJxT7', tel: '281-506-7945', mail: 'nowandforeverfry@gmail.com'},
  { id: 24, name: 'Now & Forever Mason', lat: 29.7037009, lng: -95.7444568, address: '20626 FM 1093, Katy, TX 77450', src: `${logo}`, loc: 'https://maps.app.goo.gl/g7iUBbAPoaBLYPk66', tel: '281-506-7945', mail: 'nowandforevermason@gmail.com'},
  { id: 25, name: 'Now & Forever Westheimer Parkway', lat: 29.7497892, lng: -95.8015823, address: '25645 Westheimer Pkwy, Katy, TX 77494', src: `${logo}`, loc: 'https://maps.app.goo.gl/m6Ai5hip6fQCTRfa7', tel: '281-506-7945', mail: 'nowandforeverwestheimerpkwy@gmail.com'},
];
