import img1 from "../../assets/Merchandise/merch-img-1.png"
import img2 from "../../assets/Merchandise/merch-img-2.png"
import img3 from "../../assets/Merchandise/img3.jpg"
import img4 from "../../assets/Merchandise/merch-img-4.png"
import img5 from "../../assets/Merchandise/merch-img-5.png"


export const merchData = [
    {
         img : `${img1}`,
         item : "N&F Sling",
         price: "$12.99",
        link : 'https://shop.nowandforever.com/products/n-f-sling'
    },
    {
        img : `${img2}`,
        item : "N&F 3 BULL BEANIE",
        price: "$6.99",
        link : 'https://shop.nowandforever.com/products/n-f-3-bull-beanie'
   },
   {
    img : `${img3}`,
    item : "N&F AirFreshners",
    price: "$1.39",
    link : 'https://shop.nowandforever.com/products/n-f-airfreshners'
},
{
    img : `${img4}`,
    item : "Valentines Day LOVE TRUCK - WHITE",
    price: "$19.99",
    link : 'https://shop.nowandforever.com/products/valentines-day-love-truck-white'
},
{
    img : `${img5}`,
    item : "Valentines Day TE QUIERO MOOCHO - BLACK",
    price: "$19.99",
    link : 'https://shop.nowandforever.com/products/valentines-day-te-quiero-moocho-black'
},

]